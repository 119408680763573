import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import {
  Row,
  Col,
  Gap,
  Text,
  Section,
  Title,
  Button,
  VH,
  HpTop3,
  Video,
  Earth,
  Link,
  Fill,
  ImageOverlay,
  SEO,
  HpTopCarousel,
  HpShowReel,
} from '../components'
import { up } from '../lib/styles'
import { extractNodesFromData } from '../lib/helpers'

import planeVerticalSrc from '../data/images/hp/plane-vertical.png'
import planeHorizontalSrc from '../data/images/hp/plane-horizontal.png'
import plusIconSrc from '../data/images/hp/plus-icon.png'

import jobsVideo from '../data/videos/lets-work-together.mp4'

const PlaneImageWrapper = styled.div`
  height: 125px;
  width: 100%;
  position: relative;
  z-index: -1;
  ${up('mobile')} {
    height: 200px;
  }
`

const PlaneVertical = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%) scale(0.35);
  ${up('mobile')} {
    transform: none;

    /* top: -100px; */
    top: -120px;
    left: 50px;
    max-width: 340px;
  }
`
const PlaneHorizontal = styled.img`
  position: absolute;
  top: 0;
  left: 50%;
  /* transform: translate(-46%, -48%) scale(0.25); */
  transform: translate(-43%, -47%) scale(0.45);

  ${up('mobile')} {
    transform: none;

    top: -140px;
    left: 80px;
    max-width: 700px;
  }
  ${up('tablet')} {
    transform: none;

    top: -170px;
    left: 118px;
    max-width: 700px;
  }
`

const PostTopBP = 880

const PostTopImagesSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${up(PostTopBP)} {
    flex-direction: row;
  }
`
const PostTopImageWrapper = styled.div`
  ${({ width }) => css`
    position: relative;
    width: 100%;
    height: auto;
    /* max-height: 400px; */
    ${up(PostTopBP)} {
      width: ${width || '100%'};
    }
  `}
`
const PostTopImageText = styled(Text)`
  ${({ isRight }) => css`
    z-index: 500;
    color: white;
    position: absolute;
    color: black;
    /* bottom: 18px; */
    bottom: unset;
    top: 16px;
    left: 20px;

    ${up(PostTopBP)} {
      bottom: 1%;
      left: 28px;
      ${isRight
      && css`
        right: 28px;
        left: unset;
      `}
    }
  `}

  ${up(1200)} {
    color: white;
    top: unset;
    bottom: 3%;
  }
`

const EditorContent = styled.div`
  ${({ theme: { colors } }) => css`
    p {
      font-size: 24px;
      line-height: 33px;
      font-weight: 700;
      color: ${colors.textGray};
    }
    strong {
      color: ${colors.textBlack};
    }
    a {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  `}
`

const PostImage = styled(Img)`
  height: 100%;
`

const InlineHeader = (props) => <Text as="h2" black style={{ display: 'inline', textTransform: 'uppercase' }} {...props} />

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isCareerVisible: false,
    }
  }

  careerOnMouseOver = () => {
    this.setState({ isCareerVisible: true })
  }

  careerOnMouseLeave = () => {
    this.setState({ isCareerVisible: false })
  }

  render() {
    const {
      location: { pathname },
      data: {
        topImage,
        earthImage,
        jobsBgImage,
        topImage1,
        topImage2,
        topImage3,
        postTopImage1,
        postTopImage2,
        ...restData
      } = {},
    } = this.props
    let { jobs, settings } = extractNodesFromData(restData)
    jobs = jobs.filter(({ title, content }) => !(!title || !content))

    const topImages = [topImage1, topImage2, topImage3]
    settings = settings.find(({ isHpTextVisible }) => isHpTextVisible) || {}

    const { isCareerVisible } = this.state
    return (
      <>
        {/* <Gap.Top /> */}
        <SEO
          title="Zuri Hybrid VTOL – Regional Air Mobility with 700 km Range"
          description="Experience Zuri Hybrid VTOL – 700 km range with vertical takeoff and hybrid propulsion for passengers, cargo, and emergency services."
          pathname={pathname}
        />
        {/* <HpTopCarousel images={topImages} /> */}
        <HpTop3 image={topImage} images={topImages} />
        {/* <Fill color="black" height="80px" mobilHeight="40px" /> */}
        {/* <Gap gap="80px" mobileGap="40px" bp="tablet" /> */}
        {settings.isHpTextVisible && (
          <Section>
            <Gap gap="110px" mobileGap="40px" bp="tablet" />
            <Row justifyContent="center">
              <Col maxWidth="800px" mobileMaxWidth="600px" bp="tablet" alignItems="center">
                <EditorContent dangerouslySetInnerHTML={{ __html: settings.hpText }} />
              </Col>
            </Row>
            <Gap gap="110px" mobileGap="40px" bp="tablet" />
          </Section>
        )}

        {/* <PostTopImagesSection>
          <PostTopImageWrapper width="43%">
            <PostImage fluid={postTopImage1.image.fluid} />
            <PostTopImageText>Scaled model in transition tests</PostTopImageText>
          </PostTopImageWrapper>
          <PostTopImageWrapper width="57%">
            <PostImage fluid={postTopImage2.image.fluid} />
            <PostTopImageText isRight>11-meter demonstrator in VTOL flight</PostTopImageText>
          </PostTopImageWrapper>
        </PostTopImagesSection> */}

        <Section>
          <Gap gap="110px" mobileGap="40px" bp="tablet" />
          <Row justifyContent="center">
            <Col maxWidth="800px" mobileMaxWidth="600px" bp="tablet" alignItems="center" mobileAlignItems="flex-start">
              <Title.MediumSmall as="h2">REDEFINING REGIONAL AIR MOBILITY.</Title.MediumSmall>
              <Gap gap="32px" mobileGap="24px" />
              <Text>
                <Text.Black>At Zuri, </Text.Black>
                our goal is to change the way people experience on-demand,
                door-to-door mid-range flights. Travel on your own schedule and
                without airports—secured by technology and design that
                prioritize passenger safety and comfort.
              </Text>
            </Col>
          </Row>
          <Gap gap="100px" mobileGap="40px" bp="mobile" showBelow="tablet" />
          <Gap gap="100px" showAfter="tablet" />
          <Row justifyContent="space-between" alignItems="center" responsive bp="tablet">
            <Col maxWidth="525px">
              <PlaneImageWrapper>
                <PlaneVertical src={planeVerticalSrc} alt="" />
              </PlaneImageWrapper>
              <Text>
                <InlineHeader>VERTICAL TAKEOFF AND LANDING.</InlineHeader>{' '}
                Allows you to skip airports and land everywhere from a city's periphery to the roof
                of a skyscraper.
              </Text>
            </Col>
            <Col alignItems="center" justifyContent="center" showAfter="tablet" padding="0 40px">
              <img src={plusIconSrc} alt="" />
            </Col>
            <Gap gap="80px" mobileGap="80px" bp="tablet" showBelow="tablet" />
            <Col maxWidth="575px">
              <PlaneImageWrapper>
                <PlaneHorizontal src={planeHorizontalSrc} alt="" />
              </PlaneImageWrapper>
              <Text>
                <InlineHeader>Efficient Horizontal Flight.</InlineHeader>{' '}
                The wings provide lift, which means a much better energy consumption compared to
                helicopters or multicopters, resulting in a 700+ km range.
              </Text>
            </Col>
          </Row>
          <Gap gap="60px" mobileGap="40px" bp="tablet" />
        </Section>

        <HpShowReel />

        {/* <Section.FullHeight>
          <Col width="100%" height="100%">
            <Img fluid={earthImage.image.fluid} style={{ height: '100%' }} />
          </Col>
        </Section.FullHeight> */}

        <Earth fluid={earthImage.image.fluid} />

        <Section
          image={jobsBgImage}
          video={jobsVideo}
          onMouseOver={this.careerOnMouseOver}
          onMouseLeave={this.careerOnMouseLeave}
          isBackgroundOverlaid={!isCareerVisible}
        >
          <Gap gap="200px" mobileGap="80px" bp="tablet" />
          <Col>
            <Title.Large>LET'S WORK TOGETHER</Title.Large>

            <Gap gap="120px" mobileGap="60px" bp="tablet" />

            {jobs.map(({ title, slug }) => (
              <Fragment key={slug}>
                <Link to={`${Link.CAREER}/#${slug}`}>
                  <Title.Medium>{title}</Title.Medium>
                </Link>
                <Gap gap="40px" />
              </Fragment>
            ))}
          </Col>
          <Gap gap="200px" mobileGap="80px" bp="tablet" />
          <Col alignItems="center">
            <Button.Link to={Link.CAREER}>More jobs</Button.Link>
            <Gap gap="100px" />
          </Col>
        </Section>
      </>
    )
  }
}

export default Index

export const pageQuery = graphql`
  fragment PostTopImage on File {
    image: childImageSharp {
      fluid(maxWidth: 1200, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment PostTopImage1 on File {
    image: childImageSharp {
      fluid(maxWidth: 1200, maxHeight: 510, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  fragment PostTopImage2 on File {
    image: childImageSharp {
      fluid(maxWidth: 1200, maxHeight: 510, quality: 95) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    topImage: file(relativePath: { eq: "images/hp/top.jpg" }) {
      ...FullHdImage
    }
    topImage1: file(relativePath: { eq: "images/hp/zuri-top-1.jpg" }) {
      ...FullHdImage
    }
    topImage2: file(relativePath: { eq: "images/hp/zuri-top-2.jpg" }) {
      ...FullHdImage
    }
    topImage3: file(relativePath: { eq: "images/hp/zuri-top-3.jpg" }) {
      ...FullHdImage
    }

    postTopImage1: file(relativePath: { eq: "images/hp/zuri-scaled-model.jpg" }) {
      ...PostTopImage
    }
    postTopImage2: file(relativePath: { eq: "images/hp/zuri-demonstrator.jpg" }) {
      ...PostTopImage
    }

    earthImage: file(relativePath: { eq: "images/hp/earth.jpg" }) {
      ...FullHdImage
    }
    jobsBgImage: file(relativePath: { eq: "images/hp/jobs-bg.jpg" }) {
      ...FullHdImage
    }

    jobs: allJobs(sort: { fields: sortOrder, order: ASC }, filter: { state: { eq: "published" } }) {
      edges {
        node {
          ...JobFragment
        }
      }
    }

    settings: allSettings {
      edges {
        node {
          _id
          modelName
          isHpTextVisible
          hpText
        }
      }
    }
  }
`
